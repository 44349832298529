export function partitionArray<T>(
    arr: T[],
    predicate: (item: T) => boolean
): T[][] {
    return arr.reduce(
        ([pass, fail]: T[][], elem) => {
            return predicate(elem)
                ? [[...pass, elem], fail]
                : [pass, [...fail, elem]];
        },
        [[], []]
    );
}
