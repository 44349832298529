import { trace } from "firebase/performance";

import { getPerformanceInstance } from "../firebase";
import { AddProduct } from "../types/Action";
import {
    CartError,
    Cart,
    IdentityDocument,
    CheckoutResponse,
    CartTransferError
} from "../types/Cart";
import { partitionArray } from "../utils/partitionArray";

const catchCartError = async (response: Response) => {
    if (response.ok) return response;

    const error = (await response.json()) as CartError;
    throw error;
};

const catchCartTransferError = async (response: Response) => {
    if (response.ok) return response;
    const error = (await response.json()) as CartTransferError;
    throw error;
};

export const getEventPayload = (event: AddProduct) => {
    switch (event.payload.kind) {
        case "scanned":
            return {
                barcode: event.payload.code,
                quantity: 1,
                source: "scan",
                device: event.payload.device
            };
        case "manuallyAdded":
            return {
                barcode: event.payload.code,
                quantity: event.payload.quantity,
                source: event.payload.analyticsSource
            };
    }
};

export const getQuantity = (event: AddProduct) => {
    switch (event.payload.kind) {
        case "scanned":
            return 1;
        case "manuallyAdded":
            return event.payload.quantity;
    }
};

type AddProductParameters = {
    barcode: string;
    quantity?: number;
    fromKitchen?: boolean;
    addOnOptionIds?: string[];
};

type UpdateLineItemParameters = { id: string; quantity: number };
type DeleteComboParameters = { id: string; quantity: number };

export class CartClient {
    constructor(private client: typeof fetch) {}

    public async checkin(): Promise<Cart> {
        const t = trace(getPerformanceInstance(), "checkin");
        t.start();
        const response = await this.client("/ezio/v1/start", {
            method: "POST",
            headers: { version: import.meta.env.VITE_VERSION }
        });
        t.stop();

        return await response.json();
    }

    public async add(parameters: AddProductParameters[]): Promise<Cart> {
        const t = trace(getPerformanceInstance(), "addProducts");
        t.start();

        const cart = await this.client("/ezio/v1/cart/products:batch", {
            method: "POST",
            body: JSON.stringify(parameters)
        })
            .then(catchCartError)
            .then((response) => response.json());

        t.stop();

        return cart as Cart;
    }

    public async applyCoupon(code: string): Promise<Cart> {
        const t = trace(getPerformanceInstance(), "applyCoupon");
        t.start();

        const cart = await this.client("/ezio/v1/cart/coupon", {
            method: "PUT",
            body: JSON.stringify({
                code
            })
        })
            .then(catchCartError)
            .then((response) => response.json());
        t.stop();

        return cart;
    }

    public async updateLineItems(
        lineItems: UpdateLineItemParameters[]
    ): Promise<Cart> {
        const cart = await this.client(`/ezio/v1/cart/line-items:batch`, {
            method: "PUT",
            body: JSON.stringify({ lineItems })
        })
            .then(catchCartError)
            .then((response) => response.json());

        return cart;
    }

    public async deleteCombo(updates: DeleteComboParameters[]): Promise<Cart> {
        const [toDelete, toUpdate] = partitionArray(
            updates,
            ({ quantity }) => quantity === 0
        );

        const newCart = await this.client("/ezio/v1/cart/line-items:batch", {
            method: "DELETE",
            body: JSON.stringify({
                lineItemIds: toDelete.map(({ id }) => id)
            })
        })
            .then(() =>
                this.client("/ezio/v1/cart/line-items:batch", {
                    method: "PUT",
                    body: JSON.stringify({ lineItems: toUpdate })
                })
            )
            .then((response) => response.json());

        return newCart;
    }

    public async deleteLineItems(lineItemIds: string[]): Promise<Cart> {
        const response = await this.client(`/ezio/v1/cart/line-items:batch`, {
            method: "DELETE",
            body: JSON.stringify({ lineItemIds })
        });

        return await response.json();
    }

    public async scanId(identityDocument: IdentityDocument): Promise<Cart> {
        const response = await this.client(`/ezio/v1/cart/identity`, {
            method: "POST",
            body: JSON.stringify(identityDocument)
        })
            .then(catchCartError)
            .then((response) => response.json());

        return response;
    }

    public async checkout(language: string, simulatePaymentResponse?: string) {
        const debug = simulatePaymentResponse
            ? `?simulatePaymentResponse=${simulatePaymentResponse}`
            : "";
        const t = trace(getPerformanceInstance(), "checkout");
        t.start();
        const response: CheckoutResponse = await this.client(
            `/ezio/v1/checkout${debug}`,
            {
                method: "POST",
                headers: {
                    "Accept-Language": language
                },
                signal: AbortSignal.timeout(100_000)
            }
        )
            .then(catchCartError)
            .then((result) => result.json());
        t.stop();
        return response;
    }

    public async transfer(code: string) {
        const t = trace(getPerformanceInstance(), "transfer");
        t.start();
        const response: Cart = await this.client(`/ezio/v1/cart/transfer`, {
            method: "PUT",
            body: JSON.stringify({ code })
        })
            .then(catchCartTransferError)
            .then((result) => result.json());
        t.stop();
        return response;
    }
}
