import { AnimatePresence } from "framer-motion";
import { ChangeEventHandler, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import placeholderImage from "../../../assets/placeholder.png";
import {
    KitchenProduct,
    KitchenProductAddOn,
    KitchenProductAddOnOption
} from "../../types/KitchenMenu";
import * as Price from "../../utils/Price";
import { splitArray } from "../../utils/splitArray";
import { translate } from "../../utils/translations";
import { FadeIn, SlideUp } from "../Animations";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import * as svg from "../svg";

type ProductStepProps = {
    product: KitchenProduct;
    selectedOptionIds: Set<string>;
    setSelectedOptionIds: Dispatch<SetStateAction<Set<string>>>;
};

const ProductStep = ({
    product,
    selectedOptionIds,
    setSelectedOptionIds
}: ProductStepProps) => {
    if (product.addOns.length === 0) return <></>;

    const { i18n } = useTranslation();

    const ProductAddOnOption = ({
        id,
        label,
        name,
        type,
        disabled,
        checked,
        onChange,
        testId
    }: {
        id: string;
        label: string;
        name?: string;
        type: string;
        disabled: boolean;
        checked: boolean;
        onChange: ChangeEventHandler<HTMLInputElement> | undefined;
        testId?: string;
    }) => {
        return (
            <div className="flex items-center gap-2 rounded-lg border-2 border-primary-200 bg-white p-3">
                <input
                    data-testid={testId}
                    className="peer h-5 w-5 cursor-pointer accent-primary-500 disabled:cursor-default"
                    id={`option-${id}`}
                    type={type}
                    name={name}
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                />
                <label
                    className="grow cursor-pointer text-start text-primary-600 peer-disabled:cursor-default peer-disabled:opacity-50"
                    htmlFor={`option-${id}`}
                >
                    {label}
                </label>
            </div>
        );
    };

    const CheckboxGroup = ({
        name,
        options
    }: {
        name: string;
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div
                className="grid grid-cols-2 gap-2"
                data-testid={`add-on-${name}`}
            >
                {options.map((option, index) => (
                    <ProductAddOnOption
                        testId={`add-on-option-${index}`}
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        type="checkbox"
                        disabled={!option.available}
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptions = new Set(optionIds);

                                if (optionIds.has(option.id)) {
                                    updatedOptions.delete(option.id);
                                } else {
                                    updatedOptions.add(option.id);
                                }

                                return updatedOptions;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const RadioGroup = ({
        id,
        name,
        options
    }: {
        id: string;
        name: string;
        options: KitchenProductAddOnOption[];
    }) => {
        return (
            <div
                className="grid grid-cols-2 gap-2"
                data-testid={`add-on-${name}`}
            >
                {options.map((option, index) => (
                    <ProductAddOnOption
                        testId={`add-on-option-${index}`}
                        key={option.id}
                        id={option.id}
                        label={translate(option.name, i18n)}
                        name={`${"add-on-" + id}`}
                        type="radio"
                        disabled={!option.available}
                        checked={selectedOptionIds.has(option.id)}
                        onChange={() => {
                            setSelectedOptionIds((optionIds) => {
                                const updatedOptionIds = new Set(optionIds);

                                options.forEach((addOnOption) =>
                                    updatedOptionIds.delete(addOnOption.id)
                                );
                                updatedOptionIds.add(option.id);

                                return updatedOptionIds;
                            });
                        }}
                    />
                ))}
            </div>
        );
    };

    const ProductAddOn = ({ addOn }: { addOn: KitchenProductAddOn }) => {
        return (
            <div className="flex flex-col gap-2 text-center">
                <div
                    className={`
                     text-lg text-primary-500
                    ${
                        addOn.required &&
                        "after:ml-0.5 after:text-red-500 after:content-['*']"
                    }`}
                >
                    {translate(addOn.description, i18n)}
                </div>

                {addOn.multipleChoice ? (
                    <CheckboxGroup name={addOn.name} options={addOn.options} />
                ) : (
                    <RadioGroup
                        name={addOn.name}
                        id={addOn.id}
                        options={addOn.options}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <div className="flex max-h-[34rem] min-h-[12rem] flex-1 flex-col gap-4 overflow-y-auto rounded-2xl bg-primary-100 p-3">
                {product.addOns.map((productAddOn) => (
                    <ProductAddOn addOn={productAddOn} key={productAddOn.id} />
                ))}
            </div>
        </>
    );
};

type UpsellingStepProps = {
    upsellingProducts: KitchenProduct[];
    selectedUpsellingProductBarcodes: Set<string>;
    setSelectedUpsellingProductBarcodes: Dispatch<SetStateAction<Set<string>>>;
};

const UpsellingStep = ({
    selectedUpsellingProductBarcodes,
    setSelectedUpsellingProductBarcodes,
    upsellingProducts
}: UpsellingStepProps) => {
    const { i18n, t } = useTranslation();

    return (
        <div className="flex h-full flex-1 flex-col gap-2 overflow-y-auto rounded-2xl bg-primary-100 p-3 text-center">
            <span className="text-lg text-primary-500">
                {t("kitchenMenu.upsellingTitle")}
            </span>
            <div className="grid max-h-[34rem] grid-cols-3 gap-2">
                {upsellingProducts.map((product) => (
                    <div key={product.id}>
                        <button
                            data-testid={`upselling-${product.barcode}`}
                            className={`space-between flex aspect-[0.8] w-full flex-col items-center justify-between gap-2 rounded-lg border-2 border-primary-200 bg-white p-3 text-xs disabled:opacity-50 ${
                                selectedUpsellingProductBarcodes.has(
                                    product.barcode
                                ) && "outline outline-4 outline-primary-500"
                            }`}
                            disabled={!product.available}
                            onClick={() =>
                                setSelectedUpsellingProductBarcodes(
                                    (barcodes) => {
                                        const updated = new Set(barcodes);

                                        if (barcodes.has(product.barcode)) {
                                            updated.delete(product.barcode);
                                        } else {
                                            updated.add(product.barcode);
                                        }

                                        return updated;
                                    }
                                )
                            }
                        >
                            <img className="h-20" src={product.imageUrl} />
                            <div className="col-span-7 inline-block w-full items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap text-center">
                                {translate(product.name, i18n)}
                            </div>
                            <div className="col-span-2">
                                {product.price
                                    ? ` ${Price.format(product.price)}`
                                    : "-"}
                            </div>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

type KitchenProductSelectorDialogProps = {
    product: KitchenProduct;
    upsellingProducts: KitchenProduct[];
    onConfirm: (
        productId: string,
        barcode: string,
        quantity: number,
        addOnOptionIds: string[],
        upsellingProductIds: string[]
    ) => void;
    onCancel: () => void;
};

export const KitchenProductSelectorDialog = ({
    product,
    upsellingProducts,
    onConfirm,
    onCancel
}: KitchenProductSelectorDialogProps) => {
    const { t, i18n } = useTranslation();

    const [isProductInformationOpen, setIsProductInformationOpen] =
        useState(false);

    const [selectedOptionIds, setSelectedOptionIds] = useState(
        new Set<string>(
            product.addOns
                .filter((p) => p.selectAllByDefault)
                .flatMap((p) => p.options.map((o) => o.id))
        )
    );

    const [
        selectedUpsellingProductBarcodes,
        setSelectedUpsellingProductBarcodes
    ] = useState(new Set(product.defaultUpsellingBarcodes));

    const skipAddOnsForUpselling =
        product.addOns.length === 0 && upsellingProducts.length > 0;

    const [step, setStep] = useState<"product" | "upselling">(
        skipAddOnsForUpselling ? "upselling" : "product"
    );

    const [upsellingPage, setUpsellingPage] = useState<number>(0);

    const stepBack = () => {
        if (step === "upselling" && upsellingPage > 0) {
            setUpsellingPage(Math.max(0, upsellingPage - 1));
            return;
        }

        if (step === "upselling" && product.addOns.length > 0) {
            return setStep("product");
        }

        onCancel();
    };

    const stepForward = () => {
        if (upsellingProducts.length > 0 && step === "product") {
            return setStep("upselling");
        }

        if (
            step === "upselling" &&
            upsellingPage + 1 < paginatedUpsellingProducts.length
        ) {
            return setUpsellingPage(upsellingPage + 1);
        }

        onConfirm(
            product.id,
            product.barcode,
            1,
            [...selectedOptionIds],
            [...selectedUpsellingProductBarcodes]
        );
    };

    const paginatedUpsellingProducts = splitArray(
        upsellingProducts,
        (p) => p.id === "0"
    );

    const currentTotal = (() => {
        if (!product.price) {
            return undefined;
        }

        const selectedUpsellingProductsPrice = upsellingProducts
            .filter((p) => selectedUpsellingProductBarcodes.has(p.barcode))
            .reduce((acc, cur) => acc + Number(cur.price?.value || 0), 0);

        return {
            value: `${
                selectedUpsellingProductsPrice + Number(product.price.value) ||
                0
            }`,
            currency: product.price.currency
        };
    })();

    const currentButtonLabel = (() => {
        if (
            (step === "product" && upsellingProducts.length > 0) ||
            upsellingPage + 1 < paginatedUpsellingProducts.length
        ) {
            return t("continue");
        }

        return t("addToCart");
    })();

    return (
        <AnimatePresence>
            <FadeIn
                key="fade-kitchen-product-select"
                className="pt-30 fixed inset-0 bg-primary-900 bg-opacity-75"
            />
            <SlideUp
                key="slide-kitchen-product-select"
                className="inset-x-0 bottom-0 z-20 rounded-t-xl"
            ></SlideUp>
            <div className="fixed left-1/2 top-1/2 z-20 flex w-[90vw] translate-x-[-50%] translate-y-[-50%] flex-col gap-2 rounded bg-white p-3">
                <div className="flex items-center justify-between">
                    <button
                        className="press-effect rounded bg-primary-200 p-1 text-primary-500"
                        onClick={stepBack}
                    >
                        {svg.leftArrow}
                    </button>
                    <div className="flex flex-col items-center text-center text-primary-500">
                        <img
                            src={product.imageUrl ?? placeholderImage}
                            className="h-20"
                        />
                        <span className="w-full overflow-hidden text-ellipsis text-xl font-semibold capitalize">
                            {translate(product.name, i18n).toLowerCase()}
                        </span>
                    </div>
                    <div className="h-8 w-8" />
                </div>

                {step === "product" ? (
                    <ProductStep
                        product={product}
                        setSelectedOptionIds={setSelectedOptionIds}
                        selectedOptionIds={selectedOptionIds}
                    />
                ) : (
                    <UpsellingStep
                        upsellingProducts={
                            paginatedUpsellingProducts[upsellingPage]
                        }
                        setSelectedUpsellingProductBarcodes={
                            setSelectedUpsellingProductBarcodes
                        }
                        selectedUpsellingProductBarcodes={
                            selectedUpsellingProductBarcodes
                        }
                    />
                )}

                {isProductInformationOpen && (
                    <Dialog
                        className="w-[90vw] bg-primary-500 text-center"
                        theme="kitchen"
                        onBackgroundClick={() =>
                            setIsProductInformationOpen(false)
                        }
                        primaryButton={{
                            title: t("close"),
                            action: () => setIsProductInformationOpen(false)
                        }}
                    >
                        <div className="text-2xl font-semibold text-primary-500">
                            {t("kitchenMenu.productInformation")}
                        </div>
                        <div className="w-full flex-col gap-4 bg-primary-100 p-2 text-center">
                            <div className="flex flex-col gap-1">
                                <div className="text-lg font-semibold">
                                    {t("kitchenMenu.nutritionalInformation")}
                                </div>
                                <div className="text-sm">
                                    {translate(
                                        product.nutritionalInformation,
                                        i18n
                                    ) || "-"}
                                </div>
                                <div className="flex flex-col gap-1"></div>
                                <div className="text-lg font-semibold">
                                    {t("kitchenMenu.allergens")}
                                </div>
                                <div className="text-sm">
                                    {translate(product.allergens, i18n) || "-"}
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
                <Button
                    onClick={() => setIsProductInformationOpen(true)}
                    label={t("kitchenMenu.productInformation")}
                    className="w-full text-center opacity-70"
                />
                <button
                    className="press-effect flex-none rounded bg-primary-500 p-2 text-center font-semibold leading-loose text-white disabled:opacity-30"
                    data-testid="next"
                    disabled={
                        !product.addOns
                            .filter((productAddOn) => productAddOn.required)
                            .every((productAddOn) =>
                                productAddOn.options.some((option) =>
                                    selectedOptionIds.has(option.id)
                                )
                            )
                    }
                    onClick={stepForward}
                >
                    {currentButtonLabel}
                    {currentTotal ? ` (${Price.format(currentTotal)})` : ""}
                </button>
            </div>
        </AnimatePresence>
    );
};
