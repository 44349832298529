import { useAnimate, motion } from "framer-motion";
import {
    MouseEventHandler,
    ReactElement,
    forwardRef,
    useEffect,
    useState
} from "react";
import { useTranslation } from "react-i18next";

import * as svg from "./../svg";
import { DeleteLineItemDialog } from "./DeleteLineItemDialog";
import { PromoPrice } from "./Promotion";
import { GroupPosition, SinglePosition } from "../../types/Cart";
import { format } from "../../utils/Price";
import { translate } from "../../utils/translations";
import { useConfig } from "../ConfigProvider";
import { useRootState } from "../state/StateProvider";

const LineItemActionButton = forwardRef<
    HTMLButtonElement,
    {
        image: ReactElement;
        disabled?: boolean;
        onClick?: MouseEventHandler<HTMLButtonElement>;
        testId?: string;
    }
>(function LineItemActionButton({ image, disabled, onClick, testId }, ref) {
    return (
        <button
            data-testid={testId}
            ref={ref}
            disabled={disabled}
            onClick={onClick}
            className={`grid h-7 w-7 shrink-0 place-content-center rounded border-2 border-black bg-white ${
                disabled ? "opacity-30" : ""
            }`}
        >
            {image}
        </button>
    );
});

const SingleProduct = ({ position }: { position: SinglePosition }) => {
    const { i18n } = useTranslation();
    const { dispatch } = useRootState("scan");
    const { tenant } = useConfig();

    const { product } = position;

    const updateLineItem = (action: "increment" | "decrement") => {
        dispatch({
            kind: "updateLineItem",
            product: { id: position.id, action }
        });
    };

    const deleteLineItem = () => {
        dispatch({ kind: "deleteLineItem", product: { id: position.id } });
    };

    const [showDeleteLineItemDialog, setShowDeleteLineItemDialog] =
        useState(false);

    const [incrementRef, animateIncrement] = useAnimate();
    const [decrementRef, animateDecrement] = useAnimate();
    const [lineItemRef, animateLineItem] = useAnimate();
    const [containerRef] = useAnimate();

    // tailwind classnames are not directly supported by the animation package
    const highlight = (function () {
        switch (tenant) {
            case "avec":
                return "#E5005B";
            case "kkiosk":
                return "#00C5F5";
            case "oxxo":
                return "#CF322B";
        }
    })();
    const background = "hsl(0 0 95%)";

    useEffect(() => {
        containerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });

        animateLineItem(
            lineItemRef.current,
            {
                backgroundColor: [highlight, background]
            },
            {
                duration: 1,
                ease: "linear"
            }
        );
    }, [position.quantity]);

    const grid = (
        <>
            {showDeleteLineItemDialog && (
                <DeleteLineItemDialog
                    onClose={(result) => {
                        if (result) deleteLineItem();
                        setShowDeleteLineItemDialog(false);
                    }}
                />
            )}
            <div className="flex items-center">
                <LineItemActionButton
                    testId={`decrement-line-item-${product.barcode}`}
                    ref={decrementRef}
                    image={svg.minus}
                    disabled={position.isDeleted}
                    onClick={() => {
                        animateDecrement(
                            decrementRef.current,
                            { scale: [1.15, 1] },
                            {
                                ease: "easeInOut"
                            }
                        );
                        const quantity = position.quantity - 1;
                        if (quantity < 1) {
                            setShowDeleteLineItemDialog(true);
                        } else {
                            updateLineItem("decrement");
                        }
                    }}
                />
                <div
                    className="w-10 text-center"
                    data-testid={`quantity-line-item-${product.barcode}`}
                >
                    {position.quantity}
                </div>
                <LineItemActionButton
                    testId={`increment-line-item-${product.barcode}`}
                    ref={incrementRef}
                    image={svg.plus}
                    disabled={position.isDeleted || position.quantity >= 99}
                    onClick={() => {
                        animateIncrement(
                            incrementRef.current,
                            { scale: [1.15, 1] },
                            {
                                ease: "easeOut"
                            }
                        );
                        updateLineItem("increment");
                    }}
                />
            </div>

            <div className="grow">
                <div className="font-semibold capitalize">
                    {product.name.toLocaleLowerCase()}
                </div>
                {position.addOnOptions
                    ?.map((option) => translate(option.name, i18n))
                    .join(", ")}
            </div>

            <div className="flex gap-3">
                {product.isAgeRestricted && (
                    <div className="text-primary-500">
                        {svg.eighteenPlusBadge}
                    </div>
                )}

                {position.grossPrice.value === position.linePrice.value ? (
                    format(position.linePrice)
                ) : (
                    <PromoPrice
                        originalPrice={format(position.grossPrice)}
                        discountedPrice={format(position.linePrice)}
                    />
                )}
            </div>

            <LineItemActionButton
                image={svg.trash}
                disabled={position.isDeleted}
                onClick={() => setShowDeleteLineItemDialog(true)}
            />
        </>
    );

    return (
        <motion.div
            data-testid={"lineItem".concat(product.barcode)}
            key={`single-${position.id}`}
            ref={containerRef}
            exit={{ height: 0, opacity: 0, zIndex: -1 }}
            className="last:pb-3"
        >
            <motion.div
                ref={lineItemRef}
                animate={{
                    transitionEnd: {
                        backgroundColor: background
                    }
                }}
                className={`mt-3 flex shrink-0 items-center gap-5 overflow-hidden rounded p-3 text-sm ${
                    position.isDeleted
                        ? "relative opacity-30 before:absolute before:left-0 before:right-0 before:top-1/2 before:block before:border-2 before:border-b before:border-black"
                        : ""
                }`}
            >
                {grid}
            </motion.div>
        </motion.div>
    );
};

const ProductGroup = ({ position }: { position: GroupPosition }) => {
    const { i18n } = useTranslation();
    const { dispatch } = useRootState("scan");
    const { tenant } = useConfig();

    const deleteLineItem = () => {
        dispatch({
            kind: "deleteCombo",
            position
        });
    };

    const isDeleted = false;

    const [showDeleteLineItemDialog, setShowDeleteLineItemDialog] =
        useState(false);

    const [lineItemRef, animateLineItem] = useAnimate();
    const [containerRef] = useAnimate();

    // tailwind classnames are not directly supported by the animation package
    const highlight = (function () {
        switch (tenant) {
            case "avec":
                return "#E5005B";
            case "kkiosk":
                return "#00C5F5";
            case "oxxo":
                return "#CF322B";
        }
    })();
    const background = "hsl(0 0 95%)";

    useEffect(() => {
        containerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });

        animateLineItem(
            lineItemRef.current,
            {
                backgroundColor: [highlight, background]
            },
            {
                duration: 1,
                ease: "linear"
            }
        );
    }, [position.quantity]);

    const grid = (
        <>
            {showDeleteLineItemDialog && (
                <DeleteLineItemDialog
                    onClose={(result) => {
                        if (result) deleteLineItem();
                        setShowDeleteLineItemDialog(false);
                    }}
                />
            )}
            <div className="flex items-center">
                <LineItemActionButton
                    testId={`decrement-position-${position.name}`}
                    image={svg.minus}
                    disabled
                />
                <div
                    className="w-10 text-center"
                    data-testid={`quantity-position-${position.name}`}
                >
                    {position.quantity}
                </div>
                <LineItemActionButton
                    testId={`increment-position-${position.name}`}
                    image={svg.plus}
                    disabled
                />
            </div>

            <div className="grow">
                <div className="font-semibold capitalize">
                    {position.name.toLocaleLowerCase()}
                </div>
                <div className="flex flex-col gap-1">
                    {position.lineItems?.map((lineItem, i) => (
                        <div
                            key={`group-${lineItem.id}-${i}`}
                            className="flex flex-col"
                        >
                            <div className="text-xs">
                                {lineItem.product.name}
                            </div>
                            {lineItem.addOnOptions && (
                                <div className="text-[0.6rem]">
                                    {lineItem.addOnOptions
                                        .map((o) => translate(o.name, i18n))
                                        .join(", ")}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex gap-3">
                {position.grossPrice.value === position.price.value ? (
                    format(position.price)
                ) : (
                    <PromoPrice
                        originalPrice={format(position.grossPrice)}
                        discountedPrice={format(position.price)}
                    />
                )}
            </div>

            <LineItemActionButton
                image={svg.trash}
                disabled={isDeleted}
                onClick={() => setShowDeleteLineItemDialog(true)}
            />
        </>
    );

    return (
        <motion.div
            data-testid={"position".concat(position.name)}
            key={position.name}
            ref={containerRef}
            exit={{ height: 0, opacity: 0, zIndex: -1 }}
            className="last:pb-3"
        >
            <motion.div
                ref={lineItemRef}
                animate={{
                    transitionEnd: {
                        backgroundColor: background
                    }
                }}
                className={`mt-3 flex shrink-0 items-center gap-5 overflow-hidden rounded p-3 text-sm ${
                    isDeleted
                        ? "relative opacity-30 before:absolute before:left-0 before:right-0 before:top-1/2 before:block before:border-2 before:border-b before:border-black"
                        : ""
                }`}
            >
                {grid}
            </motion.div>
        </motion.div>
    );
};

export const Product = ({
    position
}: {
    position: SinglePosition | GroupPosition;
}) =>
    position.kind === "single" ? (
        <SingleProduct position={position} />
    ) : (
        <ProductGroup position={position} />
    );
